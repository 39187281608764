import { CreatorService } from '.';
import {
  Customer,
  Invoice,
  InvoiceReceiver,
  InvoiceTextEmail,
  InvoiceVAT,
  LedgerNumber,
  Product,
  Profile,
} from '@/models';

import TnfConfig from '@/config';
import { AuthService, ProductService } from '@/lib/services/index';
import {
  ExternalInit,
  LightspeedRetailItem,
} from '@/models/external/ExternalInit';
import { extractFilename } from '@/lib/helpers';

export default class LightspeedRetailCreatorService implements CreatorService {
  private info: Record<string, any>;
  data: ExternalInit<LightspeedRetailItem>;

  public async setup(info: Record<string, any>): Promise<void> {
    // Use authservice AuthService.getAxios() until proper login flow without bearer token
    const { data } = await AuthService.getAxios().get(
      `${TnfConfig.api.base}/external/lsretail/init`,
      {
        params: info,
      },
    );

    this.info = info;
    this.data = ExternalInit.deserialize(data.data);
  }

  async getLogo(logo: string): Promise<any> {
    const { data } = await AuthService.getAxios().get(
      `${TnfConfig.api.base}/external/lsretail/logo/${logo}`,
      {
        params: this.info,
      },
    );
    return data.data;
  }

  async listReceivers(): Promise<InvoiceReceiver[]> {
    return this.data.customers.options.map((e) => e.toInvoiceReceiver());
  }

  async listProfiles(): Promise<Profile[]> {
    return this.data.profiles.options;
  }

  async searchProducts(keyword: string): Promise<Product[]> {
    const products = await ProductService.search(0, {
      term: keyword,
      limit: 5,
    });

    return products.items;
  }

  async listLedgerNumbers(): Promise<LedgerNumber[]> {
    return this.data.ledger.options;
  }

  async listVatCodes(): Promise<InvoiceVAT[]> {
    return this.data.vat.options;
  }

  async emailText(emailTextId: number): Promise<InvoiceTextEmail> {
    const text = this.data.emailText.options.find(
      (e) => +e.id === +emailTextId,
    );

    if (!text) {
      throw new Error('Emailtext not found');
    }

    return InvoiceTextEmail.fromProfileEmailText(text);
  }

  async createInvoice(invoice: Invoice): Promise<string> {
    const { data } = await AuthService.getAxios().post(
      `${TnfConfig.api.base}/external/lsretail`,
      invoice.serialize(),
      {
        params: this.info,
      },
    );
    return data.data;
  }

  async sendMail(id: string, text: InvoiceTextEmail): Promise<void> {
    await AuthService.getAxios().post(
      `${TnfConfig.api.base}/external/lsretail/${id}/send/mail`,
      text.serialize(),
      {
        params: this.info,
      },
    );
  }

  async sendDownloadPdf(id: string): Promise<File> {
    const response = await AuthService.getAxios().post(
      `${TnfConfig.api.base}/external/lsretail/${id}/send/pdf`,
      {
        params: this.info,
      },
      {
        responseType: 'blob',
      },
    );
    const filename = extractFilename(response) || 'invoice.pdf';

    return new File([response.data], filename, {
      type: response.data.type,
    });
  }

  async createCustomer(customer: Customer): Promise<number> {
    const { data } = await AuthService.getAxios().post(
      `${TnfConfig.api.base}/external/lsretail/customer`,
      customer.serialize(),
      {
        params: this.info,
      },
    );
    return data.data.id;
  }

  async updateCustomer(customer: Customer): Promise<void> {
    void customer;
    throw new Error('Method not implemented.');
  }
}
