
























import Vue from 'vue';
import { Component, Provide } from 'vue-property-decorator';
import Big from 'big.js';

import { InvoiceCreator } from '@/components/invoicecreator';
import SendInvoiceModal from '@/components/SendInvoiceModal.vue';
import LightspeedRetailCreatorService from '@/lib/services/meta/creator/lsretail';
import {
  Invoice,
  InvoiceLine,
  InvoiceStatus,
  InvoiceTextEmail,
} from '@/models/invoice';
import InvoiceCreatorConfig from '@/components/invoicecreator/config';
import { headOrSelf, sleep, unwrapError } from '@/lib/helpers';
import { downloadBlob } from '@/lib/download';

const AUTOCLOSE_DELAY = 2000;

@Component({
  components: {
    InvoiceCreator,
    SendInvoiceModal,
  },
})
export default class LightspeedRetailPage extends Vue {
  @Provide() creatorService = new LightspeedRetailCreatorService();

  loading: boolean = true;
  error: string = '';
  completed: boolean = false;
  invoice: Invoice = new Invoice();
  showSendModal: boolean = false;

  creatorConfig: InvoiceCreatorConfig = {
    disableVatChecker: false,
    disableIncludeVat: true,
    disableLineDelete: true,
    allowedTypes: [
      InvoiceStatus.DRAFT,
      InvoiceStatus.RECURRING,
      InvoiceStatus.QUOTATION,
    ],
    ghost: {},
  };

  async mounted(): Promise<void> {
    try {
      await this.creatorService.setup(this.$route.query);
      await this.prepareInvoice();

      this.loading = false;
    } catch (e) {
      this.error = unwrapError(e);
    }
  }

  async saveInvoice(invoice: Invoice): Promise<void> {
    try {
      const id = await this.creatorService.createInvoice(invoice);
      this.$toaster.success(this.$tc(`messages.success.created.invoice`), id);
      await this.complete();
    } catch (e) {
      this.$toaster.error('Error', this.$tc('messages.error.save.invoice'));
    }
  }

  async finishInvoice(
    type: string,
    invoice: Invoice,
    text: InvoiceTextEmail,
  ): Promise<boolean> {
    try {
      const id = await this.creatorService.createInvoice(invoice);
      if (!id) {
        this.$toaster.error('Error', this.$tc('messages.error.save.invoice'));
        return false;
      }

      if (type === 'email') {
        // Email it
        await this.creatorService.sendMail(id, text);
      } else {
        // Download it
        const response = await this.creatorService.sendDownloadPdf(invoice.id);
        await downloadBlob(response);
      }

      this.$toaster.success(
        this.$tc('messages.success.send.invoice.title'),
        this.$tc('messages.success.send.invoice.body'),
      );

      await this.complete();
      this.showSendModal = false;
      return true;
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.send.invoice'),
        unwrapError(e),
      );
      return false;
    }
  }

  private async complete() {
    this.completed = true;
    if (this.$route.query['returnURL']) {
      await sleep(AUTOCLOSE_DELAY);
      window.location.href = headOrSelf(this.$route.query['returnURL'])!;
    }
  }

  private async prepareInvoice() {
    const data = this.creatorService.data;

    if (data.customers.match) {
      this.invoice.receiver = data.customers.match.toInvoiceReceiver();
    }
    this.creatorConfig.ghost!.customer = {
      name: data.customers.name,
      email: data.customers.email,
      showCreateNotification: !data.customers.match,
      info: data.customers.info,
    };

    for (const item of data.items) {
      const line = new InvoiceLine(this.invoice.includesVat);

      line.quantity = new Big(item.unitQuantity);
      line.description = item.Item.description || '<unknown>';
      line.price = new Big(item.unitPrice);
      line.vat =
        data.vat.options.find((e) => e.id === data.vat.match[item.itemID]) ||
        null;
      const ledger = data.ledger.options.find(
        (e) => e.id === data.ledger.match[item.itemID],
      );
      line.ledger = ledger ? ledger.toInvoiceLedger() : null;
      line.discount = new Big(item.calcLineDiscount);

      this.invoice.lines.push(line);
    }
  }
}
